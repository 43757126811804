.card-carousel {
	/* Styles for custom navigation */
	&.custom-nav {
		/* Global styles for slick-slider */
		.slick-slider {
			@apply pb-20;
		}

		/* Global styles for slick-arrow */
		.slick-arrow {
			@apply z-[11];
		}

		/* Styles for rangeBar */
		.range-bar {
			@apply absolute -bottom-[5px] z-10 mx-auto w-full px-16 text-center md:bottom-[10px] md:px-[33%];
		}

		/* Styles for input range element */
		input[type='range'] {
			@apply p-0;
		}
	}

	@screen md {
		.slick-dots {
			@apply px-[33%];
		}

		.slick-prev {
			@apply left-1/4;
		}

		.slick-next {
			@apply right-1/4;
		}
	}

	.slick-track {
		@apply flex justify-center;
	}
}