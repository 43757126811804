.verticalyCenteredArrows,
.verticalyCenteredArrowsBlack,
.wideVerticalyCenteredArrowsBlack {
  .slick-prev,
  .slick-next {
    @apply z-10 top-1/2 bg-transparent -translate-y-1/2;
  }

  .slick-prev,
  .slick-next {
	@apply border-0;
  }

  .slick-prev {
    background-image: url(../../icons/icon-arrow-left-blue.svg);
  }

  .slick-next {
    background-image: url(../../icons/icon-arrow-right-blue.svg);
  }

  & ul {
    @apply absolute inset-x-0 -bottom-[10px];
  }
}

.verticalyCenteredArrowsBlack,
.wideVerticalyCenteredArrowsBlack {

	.slick-list {
		@apply mx-auto max-w-screen-md;
	}

	.slick-prev,
	.slick-next {
		@apply bg-black rounded-full h-8 w-8;
	}
	.slick-disabled {
		@apply opacity-20 cursor-not-allowed;
	}

	.slick-prev {
		@apply left-0;
		background-image: url(../../icons/icon-arrow-left-white.svg);
	}

	.slick-next {
		@apply right-0;
		background-image: url(../../icons/icon-arrow-right-white.svg);
	}

	.slick-prev,
	.slick-next {
		@apply border-0 md:top-auto md:-bottom-[100px];
	}

	.slick-dots li button {
		@apply w-2.5 h-2.5 bg-mid-beige;
	}

	.slick-dots li.slick-active button {
		@apply bg-black;
	}

}

.wideVerticalyCenteredArrowsBlack {
	@apply max-w-screen-xl mx-auto;
}

.wideVerticalyCenteredArrowsBlack {

	.slick-list {
		@apply max-w-full;
	}

	.slick-prev {
		@apply -left-12 2xl:left-3;
	}

	.slick-next {
		@apply -right-12 2xl:right-3;
	}

}

.hasHrBelow {
	.slick-dots {
		@apply -bottom-[100px] md:-bottom-[120px]
	}
}

.wideVerticalyCenteredArrowsBlack  {
	@apply mb-[65px];

	.slick-dots {
		@apply -bottom-[65px]
	}

}
picture[style] {
	@apply block !important;
}